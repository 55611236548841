import { useSettings } from '@wix/tpa-settings/react';
import { useEffect, useMemo, useState } from 'react';
import type { ItemImage, PopulatedMenu } from 'root/apiTypes';
import { getMenusToDisplay } from './utils';
import type { MenuOrder } from 'root/components/Menus/Settings/Tabs/MenusTab/MenusSettingsParams';
import { settingsParams } from 'root/components/Menus/settingsParams';
import type { MenusDisplayOptions } from 'root/components/Menus/Settings/Tabs/MenusTab/consts';
import { useExperiments } from '@wix/yoshi-flow-editor';
import type { LocationId, LocationsList } from 'root/types/locations';

export const useMenusLogic = ({
  menus = [],
  initialActiveTab,
}: {
  menus: PopulatedMenu[];
  initialActiveTab: number;
}) => {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const menusSideImage = settings.get(settingsParams.menusSideImage) as ItemImage;
  const menusDisplayOrder = settings.get(settingsParams.menusDisplayOrder!) as MenuOrder[];
  const menusDisplayOption = settings.get(settingsParams.menusDisplayOption) as MenusDisplayOptions;
  const [locations, setLocations] = useState<LocationsList>({});
  const [currentLocation, setCurrentLocation] = useState<LocationId>();
  const isMultiLocationExperimentEnabled = experiments.enabled('specs.restaurants.menusLivesiteMultiLocation');

  useEffect(() => {
    if (isMultiLocationExperimentEnabled) {
      const locationsRecord = menus.reduce((acc, menu) => {
        const locationId = menu.businessLocationId;
        const locationName = menu.businessLocationDetails?.name;
        if (locationId && locationName) {
          acc[locationId] = locationName;
        }
        return acc;
      }, {} as LocationsList);

      setLocations(locationsRecord);

      const locationIds = Object.keys(locationsRecord);
      if (locationIds.length > 1 && !currentLocation) {
        setCurrentLocation(locationIds[0]);
      }
    }
  }, [currentLocation, isMultiLocationExperimentEnabled, menus]);

  const showLocations = useMemo(() => {
    return isMultiLocationExperimentEnabled && Object.keys(locations).length > 1;
  }, [isMultiLocationExperimentEnabled, locations]);

  const displayableMenus = useMemo(() => {
    let filteredMenus = menus;

    if (isMultiLocationExperimentEnabled && showLocations && currentLocation) {
      filteredMenus = menus.filter((menu) => menu.businessLocationId === currentLocation);
    }

    if (!menusDisplayOrder) {
      return filteredMenus;
    }
    return getMenusToDisplay({
      menusDisplayOrder,
      menus: filteredMenus,
      menusDisplayOption,
    });
  }, [menus, isMultiLocationExperimentEnabled, showLocations, currentLocation, menusDisplayOrder, menusDisplayOption]);

  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const shouldShowTabs = displayableMenus.length > 1;

  return {
    menusSideImage,
    activeTab,
    displayableMenus,
    shouldShowTabs,
    menusDisplayOrder,
    setActiveTab,
    locations,
    showLocations,
    currentLocation,
    setCurrentLocation,
  };
};
