import React from 'react';
import { Badge, BadgePriority } from 'wix-ui-tpa/cssVars';
import type { Label as LabelType } from 'root/apiTypes';
import { dataHooks } from 'root/dataHooks';
import { classes } from './Label.st.css';
import { ReactSVG } from 'react-svg';

export const Label = ({ label }: { label: LabelType }) => {
  return (
    <Badge
      className={classes.label}
      data-hook={dataHooks.item.label}
      icon={<ReactSVG aria-label={label.name} className={classes.icon} src={label.icon?.url ?? ''} />}
      priority={BadgePriority.light}
    >
      <div className={classes.labelName}>{label.name}</div>
    </Badge>
  );
};
