import { useGetHtmlTag } from 'root/hooks/useGetHtmlTag';

export const useSectionLogic = () => {
  const sectionTitleHtmlTag = useGetHtmlTag('sectionTitleTheme');
  const sectionDescriptionHtmlTag = useGetHtmlTag('sectionDescriptionTheme');

  return {
    sectionTitleHtmlTag,
    sectionDescriptionHtmlTag,
  };
};
