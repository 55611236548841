import type { DropdownOptionProps } from 'wix-ui-tpa/cssVars';

export const useLocationsLogic = (locations: Record<string, string>) => {
  const dropdownOptions: DropdownOptionProps[] = Object.entries(locations).map(
    ([id, value]): DropdownOptionProps => ({
      id,
      value,
      isSelectable: true,
    })
  );

  return {
    dropdownOptions,
  };
};
