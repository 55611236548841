import React from 'react';
import { Dropdown, DropdownTheme } from 'wix-ui-tpa/cssVars';
import { dataHooks } from 'root/dataHooks';
import { useLocationsLogic } from './Locations.logic';
import type { LocationsList } from 'root/types/locations';

export const Locations = ({
  locations,
  currentLocation,
  setCurrentLocation,
}: {
  locations: LocationsList;
  currentLocation: string;
  setCurrentLocation: (location: string) => void;
}) => {
  const { dropdownOptions } = useLocationsLogic(locations);
  return (
    <div data-hook={dataHooks.locations.container}>
      {dropdownOptions && (
        <Dropdown
          data-hook={dataHooks.locations.dropdown}
          options={dropdownOptions}
          selectedId={currentLocation}
          theme={DropdownTheme.Line}
          onChange={(option) => setCurrentLocation(option.id ?? '')}
        />
      )}
    </div>
  );
};
