import type { ImageSizeCategories } from 'root/consts';
import { ImagePosition, Alignment, AlignmentText, ImageSize, NavigationAlignment } from 'root/consts';
import { getAlignmentText } from 'root/utils/alignment';
import { hasPriceVariantDividers, Preset } from 'root/utils/presets';
import { getIsEndPosition } from './Widget/utils';

export const getWidgetPadding = ({
  preset,
  gridMenuVerticalPadding,
  gridMenuHorizontalPadding,
  columnsMenuVerticalPadding,
  columnsMenuHorizontalPadding,
  centerMenuVerticalPadding,
  centerMenuHorizontalPadding,
  sideBySideMenuVerticalPadding,
  sideBySideMenuHorizontalPadding,
}: {
  preset: Preset;
  gridMenuVerticalPadding: number;
  gridMenuHorizontalPadding: number;
  columnsMenuVerticalPadding: number;
  columnsMenuHorizontalPadding: number;
  centerMenuVerticalPadding: number;
  centerMenuHorizontalPadding: number;
  sideBySideMenuVerticalPadding: number;
  sideBySideMenuHorizontalPadding: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      return `${gridMenuVerticalPadding}px ${gridMenuHorizontalPadding}px`;
    case Preset.Columns:
      return `${columnsMenuVerticalPadding}px ${columnsMenuHorizontalPadding}px`;
    case Preset.Center:
      return `${centerMenuVerticalPadding}px ${centerMenuHorizontalPadding}px`;
    case Preset.SideBySide:
      return `${sideBySideMenuVerticalPadding}px ${sideBySideMenuHorizontalPadding}px`;
  }
};

export const getWidgetOverflow = ({ preset, isMobile }: { preset: Preset; isMobile: boolean }) => {
  return preset === Preset.SideBySide && !isMobile ? 'visible' : 'hidden';
};

export const getWidgetDisplay = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? 'grid' : 'block';
};

export const getWidgetGridTemplate = ({
  preset,
  isMobile,
  isRTL,
  imagePosition,
}: {
  preset: Preset;
  isMobile: boolean;
  isRTL: boolean;
  imagePosition: ImagePosition;
}) => {
  if (preset !== Preset.SideBySide) {
    return '';
  }

  const isEndPosition = getIsEndPosition(isRTL, imagePosition, ImagePosition.Left);

  if (isMobile) {
    return `"nav"
            "image"
            "menu"
            / 1fr`;
  }

  return isEndPosition
    ? `". image" 20px
       "nav image"
       "menu image"
       ". image" 20px
       / 1fr 1fr`
    : `"image ." 20px
       "image nav"
       "image menu"
       "image ." 20px
       / 1fr 1fr`;
};

export const getNavigationContainerGridArea = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? 'nav' : '';
};

export const getNavigationContainerPadding = ({ preset, isMobile }: { preset: Preset; isMobile: boolean }) => {
  return preset === Preset.SideBySide && !isMobile ? '0px 40px' : '0px';
};

export const getMenuContentGridArea = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? 'menu' : '';
};

export const getMenuContentPadding = ({ preset, isMobile }: { preset: Preset; isMobile: boolean }) => {
  return preset === Preset.SideBySide && !isMobile ? '0px 40px' : '0px';
};

export const getImageContainerGridArea = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? 'image' : '';
};

export const getImageContainerPosition = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? 'sticky' : '';
};

export const getImageContainerTop = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? 0 : '';
};

export const getImageContainerWidth = ({ preset }: { preset: Preset }) => {
  return preset === Preset.SideBySide ? '100%' : '';
};

export const getImageContainerHeight = ({ preset, isMobile }: { preset: Preset; isMobile: boolean }) => {
  return preset === Preset.SideBySide ? (isMobile ? '20vh' : '100vh') : '';
};

export const getImageContainerPaddingBottom = ({ preset, isMobile }: { preset: Preset; isMobile: boolean }) => {
  return preset === Preset.SideBySide && isMobile ? '40px' : '';
};

export const getNavigationMargin = ({ isMobile }: { isMobile: boolean }) => {
  return isMobile ? '40px' : '80px';
};

export const getMenuMaxWidth = ({ preset }: { preset: Preset }) => {
  return preset === Preset.Center ? '800px' : '';
};

export const getMenuMargin = ({ preset }: { preset: Preset }) => {
  return preset === Preset.Center ? '0 auto' : '';
};

export const getSectionsDisplay = ({ preset }: { preset: Preset }) => {
  switch (preset) {
    case Preset.Grid:
    case Preset.Center:
      return 'grid';
    case Preset.Columns:
    case Preset.SideBySide:
      return 'block';
  }
};

export const getSectionsGridTemplateColumns = ({ preset }: { preset: Preset }) => {
  switch (preset) {
    case Preset.Grid:
    case Preset.Center:
      return '1fr';
    case Preset.Columns:
    case Preset.SideBySide:
      return '';
  }
};

export const getSectionsRowGap = ({
  preset,
  gridGap,
  centerGap,
}: {
  preset: Preset;
  gridGap: number;
  centerGap: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      return `${gridGap}px`;
    case Preset.Center:
      return `${centerGap}px`;
    case Preset.Columns:
    case Preset.SideBySide:
      return '';
  }
};

export const getSectionsMarginTop = ({ preset }: { preset: Preset }) => {
  return preset === Preset.Center ? '0px' : '40px';
};

export const getSectionsColumns = ({
  preset,
  isMobile,
  columnsNumOfColumns,
}: {
  preset: Preset;
  isMobile: boolean;
  columnsNumOfColumns: number;
}) => {
  if (preset === Preset.Columns) {
    if (isMobile) {
      return 1;
    }

    return columnsNumOfColumns;
  }

  if (preset === Preset.SideBySide) {
    return 1;
  }

  return '';
};

export const getSectionsColumnsGap = ({ preset, columnsGap }: { preset: Preset; columnsGap: number }) => {
  if (preset === Preset.Columns) {
    return `${columnsGap}px`;
  }

  return '';
};

export const getSectionPadding = ({
  preset,
  gridPadding,
  columnsPadding,
  centerPadding,
  sideBySidePadding,
}: {
  preset: Preset;
  gridPadding: number;
  columnsPadding: number;
  centerPadding: number;
  sideBySidePadding: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      return `${gridPadding}px`;
    case Preset.Columns:
      return `${columnsPadding}px`;
    case Preset.Center:
      return `${centerPadding}px`;
    case Preset.SideBySide:
      return `${sideBySidePadding}px`;
  }
};

export const getSectionMarginBottom = ({
  preset,
  columnsMarginBottom,
  sideBySideMarginBottom,
}: {
  preset: Preset;
  columnsMarginBottom: number;
  sideBySideMarginBottom: number;
}) => {
  switch (preset) {
    case Preset.Columns:
      return `${columnsMarginBottom}px`;
    case Preset.SideBySide:
      return `${sideBySideMarginBottom}px`;
    default:
      return '';
  }
};

export const getFirstSectionMarginTop = ({ preset }: { preset: Preset }) => {
  return preset === Preset.Center ? '40px' : '';
};

export const getSectionTopDividerMarginBottom = ({
  preset,
  centerSectionDividerMargin,
  sectionTopDividerWidth,
}: {
  preset: Preset;
  centerSectionDividerMargin: number;
  sectionTopDividerWidth: number;
}) => {
  if (sectionTopDividerWidth === 0) {
    return '0px';
  }

  return preset === Preset.Center ? `${centerSectionDividerMargin}px` : '15px';
};

export const getSectionBottomDividerMarginTop = ({
  sectionBottomDividerWidth,
}: {
  sectionBottomDividerWidth: number;
}) => {
  if (sectionBottomDividerWidth === 0) {
    return '0px';
  }

  return '15px';
};

export const getItemsDisplay = ({ preset }: { preset: Preset }) => {
  switch (preset) {
    case Preset.Grid:
    case Preset.Center:
      return 'grid';
    default:
      return '';
  }
};
export const getItemsGridTemplateColumns = ({
  preset,
  isMobile,
  gridNumOfColumns,
}: {
  preset: Preset;
  isMobile: boolean;
  gridNumOfColumns: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      const gridColumns = isMobile ? Math.min(gridNumOfColumns, 2) : gridNumOfColumns;
      return `repeat(${gridColumns}, 1fr)`;
    case Preset.Center:
      return '1fr';
    default:
      return '';
  }
};

export const getItemsColumnGap = ({ preset, gridItemsColumnGap }: { preset: Preset; gridItemsColumnGap: number }) => {
  if (preset === Preset.Grid) {
    return `${gridItemsColumnGap}px`;
  }

  return '';
};

export const getItemsRowGap = ({
  preset,
  gridItemsRowGap,
  centerItemsRowGap,
}: {
  preset: Preset;
  gridItemsRowGap: number;
  centerItemsRowGap: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      return `${gridItemsRowGap}px`;
    case Preset.Center:
      return `${centerItemsRowGap}px`;
    default:
      return '';
  }
};

export const getItemRootMarginBottom = ({
  preset,
  columnsItemsGap,
  sideBySideItemsGap,
}: {
  preset: Preset;
  columnsItemsGap: number;
  sideBySideItemsGap: number;
}) => {
  switch (preset) {
    case Preset.Columns:
      return `${columnsItemsGap}px`;
    case Preset.SideBySide:
      return `${sideBySideItemsGap}px`;
    default:
      return '';
  }
};

export const getItemGridTemplate = ({
  preset,
  isRTL,
  gridItemDetailsPadding,
  columnsImageWidth,
  centerItemDetailsPadding,
  columnsImagePosition,
  showItemImage,
}: {
  preset: Preset;
  isRTL: boolean;
  gridItemDetailsPadding: number;
  columnsImageWidth: number;
  centerItemDetailsPadding: number;
  columnsImagePosition: number;
  showItemImage: boolean;
}) => {
  switch (preset) {
    case Preset.Grid:
      return `[row1-start] "image image image" [row1-end]
      [row2-start] ". name ." [row2-end]
      [row3-start] ". description ." [row3-end]
      [row4-start] ". labels ." [row4-end]
      [row5-start] ". price ." [row5-end]
      / ${gridItemDetailsPadding}px 1fr ${gridItemDetailsPadding}px`;
    case Preset.Columns:
      const isEndPosition = getIsEndPosition(isRTL, columnsImagePosition, ImagePosition.Right);

      if (!showItemImage) {
        return `[row1-start] "divider divider divider" [row1-end]
                [row2-start] ". . ." 16px [row2-end]
                [row3-start] "name . price" [row3-end]
                [row4-start] "description . ." [row4-end]
                [row5-start] "labels . ." [row5-end]
                [row6-start] "price-variants price-variants price-variants" [row6-end]
                / 1fr 25% auto`;
      }

      return isEndPosition
        ? `[row1-start] "divider divider divider divider divider" [row1-end]
           [row2-start] ". . . . ." 16px [row2-end]
           [row3-start] "image . name . price" minmax(0, max-content) [row3-end]
           [row4-start] "image . description . ."  minmax(0, max-content) [row4-end]
           [row5-start] "image . labels . . "  minmax(0, max-content) [row5-end]
           [row6-start] "image . price-variants price-variants price-variants"  minmax(0, 1fr) [row6-end]
           / ${columnsImageWidth}% 20px 1fr 20px auto`
        : `[row1-start] "divider divider divider divider divider" [row1-end]
           [row2-start] ". . . . ." 16px [row2-end]
           [row3-start] "name . price . image" minmax(0, max-content) [row3-end]
           [row4-start] "description . . . image"  minmax(0, max-content) [row4-end]
           [row5-start] "labels . . . image"  minmax(0, max-content) [row5-end]
           [row6-start] "price-variants price-variants price-variants . image"  minmax(0, 1fr) [row6-end]
           / 1fr 20px auto 20px ${columnsImageWidth}%`;
    case Preset.Center:
      return ` [row1-start] ". name ." [row1-end]
               [row2-start] ". description ." [row2-end]
               [row3-start] ". labels ." [row3-end]
               [row4-start] ". price ." [row4-end]
    / ${centerItemDetailsPadding}px 1fr ${centerItemDetailsPadding}px`;
    case Preset.SideBySide:
      return `[row1-start] "divider divider divider" [row1-end]
              [row2-start] ". . ." 16px [row2-end]
              [row3-start] "name . price" [row3-end]
              [row4-start] "description . ." [row4-end]
              [row5-start] "labels . ." [row5-end]
              [row6-start] "price-variants price-variants price-variants" [row6-end]
              [row7-start] ". . ." 4px [row7-end]
              / 1fr 25% auto`;
  }
};

export const getItemGridTemplateNoImage = ({
  preset,
  isRTL,
  gridItemDetailsPadding,
  columnsImageWidth,
  centerItemDetailsPadding,
  columnsImagePosition,
}: {
  preset: Preset;
  isRTL: boolean;
  gridItemDetailsPadding: number;
  columnsImageWidth: number;
  centerItemDetailsPadding: number;
  columnsImagePosition: number;
}) => {
  return getItemGridTemplate({
    preset,
    isRTL,
    gridItemDetailsPadding,
    columnsImageWidth,
    centerItemDetailsPadding,
    columnsImagePosition,
    showItemImage: false,
  });
};

export const getItemPriceJustifySelf = ({ preset }: { preset: Preset }) => {
  return [Preset.Columns, Preset.SideBySide].includes(preset) ? 'flex-end' : '';
};

export const getItemPriceMarginBottom = ({ preset }: { preset: Preset }) => {
  return preset === Preset.Center ? '0px' : '12px';
};

export const getItemImageAspectRatio = ({
  preset,
  gridItemImageRatio,
  columnsItemImageRatio,
}: {
  preset: Preset;
  gridItemImageRatio: number;
  columnsItemImageRatio: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      return gridItemImageRatio;
    case Preset.Columns:
      return columnsItemImageRatio;
    default:
      return '';
  }
};

export const getItemImageMarginBottom = ({
  preset,
  gridItemImageBottomPadding,
}: {
  preset: Preset;
  gridItemImageBottomPadding: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      return `${gridItemImageBottomPadding}px`;
    case Preset.Columns:
      return '12px';
    default:
      return '';
  }
};

export const getItemPadding = ({
  preset,
  gridItemPadding,
  columnsItemPadding,
  sideBySideItemPadding,
  centerItemPadding,
}: {
  preset: Preset;
  gridItemPadding: number;
  columnsItemPadding: number;
  sideBySideItemPadding: number;
  centerItemPadding: number;
}) => {
  switch (preset) {
    case Preset.Grid:
      return `${gridItemPadding}px`;
    case Preset.Columns:
      return `${columnsItemPadding}px`;
    case Preset.Center:
      return `${centerItemPadding}px`;
    case Preset.SideBySide:
      return `${sideBySideItemPadding}px`;
  }
};

export const getPriceVariantsGridArea = ({ preset }: { preset: Preset }) => {
  return [Preset.Columns, Preset.SideBySide].includes(preset) ? 'price-variants' : 'price';
};

export const getPriceVariantsDisplay = ({ preset }: { preset: Preset }) => {
  return [Preset.Center].includes(preset) ? 'flex' : 'grid';
};

export const getPriceVariantsJustifyContent = ({ preset }: { preset: Preset }) => {
  return [Preset.Center].includes(preset) ? 'center' : '';
};

export const getPriceVariantsAlignItems = ({ preset }: { preset: Preset }) => {
  return [Preset.Center].includes(preset) ? 'center' : '';
};

export const getPriceVariantsFlexWrap = ({ preset }: { preset: Preset }) => {
  return [Preset.Center].includes(preset) ? 'wrap' : '';
};

export const getPriceVariantsGap = ({ preset }: { preset: Preset }) => {
  return [Preset.Center].includes(preset) ? '0px' : '2px';
};

export const getPriceVariantsMarginBottom = ({ preset }: { preset: Preset }) => {
  return [Preset.Center].includes(preset) ? '0px' : '12px';
};

export const getVariantDisplay = ({ preset }: { preset: Preset }) => {
  return [Preset.Center].includes(preset) ? 'grid' : '';
};

export const getVariantGridTemplate = ({ preset }: { preset: Preset }) => {
  return [Preset.Center].includes(preset)
    ? `[row1-start] ". variant-details . variant-divider" [row1-end]
       / 16px auto 16px auto`
    : '';
};

export const getVariantAlignItems = ({ preset }: { preset: Preset }) => {
  return [Preset.Center].includes(preset) ? 'center' : '';
};

export const getVariantWidth = ({ preset }: { preset: Preset }) => {
  return [Preset.Columns, Preset.SideBySide].includes(preset) ? '100%' : '';
};

export const getVariantDetailsJustifyContent = ({ preset }: { preset: Preset }) => {
  return [Preset.Columns, Preset.SideBySide].includes(preset) ? 'space-between' : '';
};

export const getVariantDetailsGridArea = ({ preset }: { preset: Preset }) => {
  return [Preset.Center].includes(preset) ? 'variant-details' : '';
};

export const getColumnsImageWidth = ({
  imageWidthEnum,
  isMobile,
}: {
  imageWidthEnum: ImageSizeCategories;
  isMobile: boolean;
}) => {
  const widthMap = isMobile ? ImageSize.MOBILE : ImageSize.DESKTOP;

  return widthMap[imageWidthEnum];
};

export const getMenuTextAlignment = ({
  gridMenuAlignment,
  columnsMenuAlignment,
  sideBySideMenuAlignment,
  preset,
}: {
  gridMenuAlignment: Alignment;
  columnsMenuAlignment: Alignment;
  sideBySideMenuAlignment: Alignment;
  preset: Preset;
}) => {
  const gridAlignmentText = getAlignmentText(gridMenuAlignment);
  const columnsAlignmentText = getAlignmentText(columnsMenuAlignment);
  const sideBySideAlignmentText = getAlignmentText(sideBySideMenuAlignment);

  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: gridAlignmentText,
    [Preset.Columns]: columnsAlignmentText,
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.SideBySide]: sideBySideAlignmentText,
  };

  return alignmentMap[preset];
};

export const getSectionTextAlignment = ({
  gridSectionAlignment,
  columnsSectionAlignment,
  sideBySideSectionAlignment,
  preset,
}: {
  gridSectionAlignment: Alignment;
  columnsSectionAlignment: Alignment;
  sideBySideSectionAlignment: Alignment;
  preset: Preset;
}) => {
  const gridAlignmentText = getAlignmentText(gridSectionAlignment);
  const columnsAlignmentText = getAlignmentText(columnsSectionAlignment);
  const sideBySideAlignmentText = getAlignmentText(sideBySideSectionAlignment);

  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: gridAlignmentText,
    [Preset.Columns]: columnsAlignmentText,
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.SideBySide]: sideBySideAlignmentText,
  };

  return alignmentMap[preset];
};

export const getItemTextAlignment = ({
  gridItemAlignment,
  preset,
}: {
  gridItemAlignment: Alignment;
  preset: Preset;
}) => {
  const gridAlignmentText = getAlignmentText(gridItemAlignment);

  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: gridAlignmentText,
    [Preset.Columns]: getAlignmentText(Alignment.Start),
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.SideBySide]: getAlignmentText(Alignment.Start),
  };

  return alignmentMap[preset];
};

export const getNavigationTextAlignment = ({
  gridMenuNavigationAlignment,
  columnsMenuNavigationAlignment,
  sideBySideMenuNavigationAlignment,
  preset,
  isRTL,
}: {
  gridMenuNavigationAlignment: Alignment;
  columnsMenuNavigationAlignment: Alignment;
  sideBySideMenuNavigationAlignment: Alignment;
  preset: Preset;
  isRTL: boolean;
}) => {
  const gridAlignmentText = getAlignmentText(gridMenuNavigationAlignment);
  const columnsAlignmentText = getAlignmentText(columnsMenuNavigationAlignment);
  const sideBySideAlignmentText = getAlignmentText(sideBySideMenuNavigationAlignment);

  const getNavigationAlignmentByLanguage = (currentAlignment: string): string => {
    switch (currentAlignment) {
      case AlignmentText.Center:
        return NavigationAlignment.Center;
      case AlignmentText.Start:
        return isRTL ? NavigationAlignment.Right : NavigationAlignment.Left;
      case AlignmentText.End:
      default:
        return isRTL ? NavigationAlignment.Left : NavigationAlignment.Right;
    }
  };

  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: getNavigationAlignmentByLanguage(gridAlignmentText),
    [Preset.Columns]: getNavigationAlignmentByLanguage(columnsAlignmentText),
    [Preset.Center]: NavigationAlignment.Center,
    [Preset.SideBySide]: getNavigationAlignmentByLanguage(sideBySideAlignmentText),
  };

  return alignmentMap[preset];
};

export const showPriceVariantsDivider = ({
  preset,
  showItemVariantsDivider,
}: {
  preset: Preset;
  showItemVariantsDivider: boolean;
}) => {
  return hasPriceVariantDividers(preset) && showItemVariantsDivider ? 'block' : 'none';
};

export const showMenuSideImage = ({
  preset,
  isMobile,
  showMenusSideImage,
}: {
  preset: Preset;
  isMobile: boolean;
  showMenusSideImage: boolean;
}) => {
  return preset === Preset.SideBySide && (!isMobile || showMenusSideImage) ? 'block' : 'none';
};

export const getElementDisplay = ({
  shouldShow,
  displayOption = 'block',
}: {
  shouldShow: boolean;
  displayOption?: string;
}) => {
  return shouldShow ? displayOption : 'none';
};
