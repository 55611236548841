import { useCallback } from 'react';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import type { PopulatedMenu } from 'root/apiTypes';
import type { NavigateToFn, NavigateToParams } from 'root/clientTypes';
import {
  OverflowBehavior,
  TabsVariant,
  type DropdownOptionProps,
  type TabItem,
  type TabsProps,
} from 'wix-ui-tpa/cssVars';
import { useGetNavigationLayout } from 'root/hooks/preset/useGetNavigationLayout';
import { useGetNavigationFullWidthTab } from 'root/hooks/preset/useGetNavigationFullWidthTab';
import { buildMenuUrlFromCurrentUrl } from 'root/utils/navigation';
import { useExperiments, type ILocation } from '@wix/yoshi-flow-editor';
import { MenuListNavigation, Overflow } from 'root/consts';
import { useNavigationBi } from './Navigation.bi';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from 'root/components/Menus/stylesParams';
import { getNavigationTextAlignment } from 'root/components/Menus/stylesParamsUtils';
import { useMenusContext } from 'root/contexts/MenusContext';

type UseNavigationLogicParams = {
  menus: PopulatedMenu[];
  setActiveItem: (index: number) => void;
  navigateTo: NavigateToFn;
  currentUrl: ILocation['url'];
};

export const useNavigationLogic = ({ menus, setActiveItem, navigateTo, currentUrl }: UseNavigationLogicParams) => {
  const { preset, tabsOverflow } = useGetLayoutStylesParams();
  const { reportMenuNavigationClick } = useNavigationBi();
  const styles = useStyles();
  const { isRTL } = useMenusContext();

  const navigationDropdownStyle = styles.get(stylesParams.navigationDropdownStyle);
  const navigationTextAlignment = getNavigationTextAlignment({
    preset,
    isRTL,
    gridMenuNavigationAlignment: styles.get(stylesParams.gridMenuNavigationAlignment),
    columnsMenuNavigationAlignment: styles.get(stylesParams.columnsMenuNavigationAlignment),
    sideBySideMenuNavigationAlignment: styles.get(stylesParams.sideBySideMenuNavigationAlignment),
  });

  const navigationLayout = useGetNavigationLayout(preset);
  const navigationFullWidthTab = useGetNavigationFullWidthTab(preset);
  const { experiments } = useExperiments();
  const isTabsPanelExperimentEnabled = experiments.enabled('specs.restaurants.menusOoiTabsPanel');

  const renderButHideTabsForSeo = navigationLayout === MenuListNavigation.Dropdown;

  const getMenuNavigationDetailsByIndex = useCallback(
    (index: number): NavigateToParams => {
      const menu = menus[index];
      const menuId = menu.id;
      const urlQueryParam = menu.urlQueryParam;
      return { currentUrl, menuId, urlQueryParam, isFirst: index === 0 };
    },
    [currentUrl, menus]
  );

  const tabsWidth = navigationFullWidthTab ? TabsVariant.fullWidth : TabsVariant.fit;
  const tabOptions = menus.map((menu, index): TabItem => {
    return {
      title: menu?.name,
      id: index.toString(),
      href: buildMenuUrlFromCurrentUrl(getMenuNavigationDetailsByIndex(index)),
    };
  });

  const dropdownOptions: DropdownOptionProps[] = tabOptions.map(
    (option): DropdownOptionProps => ({
      id: option.id,
      value: option.title,
      isSelectable: true,
    })
  );

  const selectMenu = (index: number) => {
    const menu = getMenuNavigationDetailsByIndex(index);
    setActiveItem(index);
    navigateTo(menu);
    reportMenuNavigationClick({
      navigationLayout,
      numMenus: menus.length,
    });
  };

  const onClickTab: TabsProps['onTabClick'] = (index, e) => {
    e.preventDefault();
    selectMenu(index);
  };

  const onClickNavigationDropdownOption = (option: DropdownOptionProps) => {
    selectMenu(Number(option.id));
  };

  const overflowBehavior =
    tabsOverflow === Overflow.Wrap && isTabsPanelExperimentEnabled ? OverflowBehavior.wrap : OverflowBehavior.scroll;

  return {
    navigationLayout,
    tabsWidth,
    dropdownOptions,
    tabOptions,
    onClickNavigationDropdownOption,
    onClickTab,
    navigationDropdownStyle,
    renderButHideTabsForSeo,
    overflowBehavior,
    navigationTextAlignment,
  };
};
